<template>
	<div v-if="form && Object.keys(form).length > 0" class="mat40">
		<PanelInfoBox v-if="submitSuccess">
			<img src="../assets/images/icons/checkmark-circle.svg" style="max-height: 48px" />
			<p class="text-lg"><b>Dane zostały poprawnie zapisane</b></p>  
		</PanelInfoBox>
		<panel-competition-form-box class="panel-form mab24">
			<div class="col-12 mab24">
				<h2>Własny wygląd</h2>
			</div>
			<div class="row mab16" style="width: 100%;">
				<div class="col-12 col-md-6">
					<div class="row mab16" style="width: 100%;">
						<div class="col-12 mab16">
							<p class="form-input__label">Ustaw kolor podstawowy</p>
							<div class="form-input__colorpicker">
								<input v-model="form.colourDefault" id="coloris" />
							</div>                       
						</div>
						<div class="col-12">
							<p class="form-input__label">Wybierz swoje logo (plik jpg lub png)</p>
							<base-input-file
								v-model="files"
								:isMultiple="false"
								converter="base64"
								accept="image/png, image/jpeg, image/jpg"
								hint="Wybierz plik"
							/>
						</div>
						<div v-if="form.logoLink" class="col-12 mat16">
							<p class="form-input__label">Obecne logo</p>
							<img :src="form.logoLink" :alt="form.logoFilename" class="mab16" />

							<base-button btn2 @click="removeLogo" short>Usuń plik</base-button>
						</div>
					</div>
				</div>	
				<div class="col-12 col-md-6">
					<div class="malr24">
						<p class="form-input__helper">Dostosuj wygląd systemu do własnych upodobań. Ustaw kolor podstawowy oraz kolor rozszerzony i dodaj własne logo. Dzięki temu zintegrujesz wizualnie nasz system ze swoim kolorami firmowymi.</p>
					</div>
				</div>			
			</div>
		</panel-competition-form-box>
		<div class="col-lg-12 mat16 mab64">
			<div class="row justify-content-end">
			<div class="col-12 col-lg-4">
				<base-button btn1 @click="saveForm">Zapisz</base-button>
			</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import PanelCompetitionFormBox from '@/components/PanelCompetitionFormBox';
import Coloris from "@melloware/coloris";
import PanelInfoBox from '@/components/PanelInfoBox'


export default {
	name: 'PanelAccountSettingsCustom',
	props: {
        id: {
            type: Number,
            default: null,
        },
	},
	components: {
		PanelInfoBox,
		PanelCompetitionFormBox,
	},
	data() {
		return {
			loading: false,
			role: null,
			form: {
				colourDefault: null
			},
			errors: null,
			files: []
		}
	},
	computed: {
        ...mapGetters(['user']),
		operatorId() {
			return this.organizerId ? null : this.user.operator
		},
		organizerId() {
			return this.$route.params?.id || this.user.organizer
		},
    },
	created() {
		this.init()
	},
	watch: {
		submitSuccess: (isSuccess) => {
			if (isSuccess) {
				document.querySelector('body').scrollIntoView({
					top: 0,
					behavior: 'smooth',
				});
			}
		},
	},
	methods: {
		init() {
			if (this.organizerId || this.operatorId) {
				this.getRoleData(this.organizerId || this.operatorId, this.organizerId ? 'organizers' : 'operators')
			}
		},
		async getRoleData(id, role) {
			try {
				const response = await axios.get(`/panel/${role}/${id}/layout`);
				if (response) {
					this.role = JSON.parse(JSON.stringify(response))
					this.form = response
				}
				this.$nextTick(() => {
					Coloris.init();
					Coloris({el: "#coloris"});
				})
			} catch (e) {
				this.errors = e;
				return;
			}
		},
		async saveForm() {
            this.loading = true
            const promises = []

			const layoutPOST = axios({
				method: 'post',
				url: '/panel/layout',
				data: {
					"operator": this.operatorId,
					"organizer": this.organizerId,
					"colourDefault": this.form.colourDefault
				}                 
			})
			promises.push(layoutPOST)

			if (this.files && this.files.length > 0) {
				const url = this.organizerId ? `/panel/organizers/${this.organizerId}/files/logo` : `/panel/operators/${this.operatorId}/files/logo`
				const filesPOST = axios({
					method: 'post',
					url,
					data: this.files[0]               
				})
				promises.push(filesPOST)
			}
            await Promise.all(promises).catch(err => {
                this.errors = err;
            });
			this.files = []
            this.loading = false
			this.submitSuccess = true
			this.init()
        },
		async removeLogo() {
			const url = this.organizerId ? `/panel/organizers/${this.organizerId}/files/logo` : `/panel/operators/${this.operatorId}/files/logo`
			await axios({
				method: 'delete',
				url,
			})
			this.init()
		},
	}
}
</script>
<style src="@melloware/coloris/dist/coloris.css"></style>
<style>
.form-input__colorpicker .clr-field button {
    width: 32px;
    height: 32px;
    left: 6px;
    right: auto;
    border-radius: 4px;
	z-index: 22;
}
.form-input__colorpicker .clr-field  {
	width: 100%;
}
.form-input__colorpicker .clr-field input {
	padding-left: 48px;
	position: relative;
    min-height: 48px;
    border: solid 1px var(--color-slate-two);
    border-radius: 4px;
	width: 100%;
    box-sizing: border-box;
}
</style>
<style scoped>
.info-box {
    padding: 30px;
	border-radius: 4px;
}
.info-box.info-bg {
	background-color: #fff;
	color: #37474f;
    border-radius: 4px 4px 0px 0px;
}
.info-box.alert-bg {
	background-color: #ffe2e8;
	color: #37474f;
    border-radius:0px 0px 4px 4px;
}
.form-input__label {
	margin-bottom: 6px;
}
.row.unset {
    justify-content: unset;
}
@media (max-width: 991px) {
	.form-input__helper {
		margin: 8px 0;
	}
}
@media (min-width: 992px) {
    .malr8 {
        margin-left: 8px;
    }
    .malr24 {
        margin-left: 24px;
    }
}
</style>	