<template>
	<div>
		<modal ref="checkOrganizerSetupModal" id="checkOrganizerSetupModal" class="modal-background" @modal-close="$emit('close')">
			<template v-slot:header>Uzupełnij dane</template>
			<template v-slot:body>
				<div class="info-box info-bg">
					<div class="row">
						<div class="col-12 col-md-6">
							<h3 class="mab16" style="line-height: 1.1">Uzupełnij dane organizatora i zaakceptuj umowę powierzenia przetwarzania danych osobowych.</h3>
							<p>Aby dostosować działanie systemu do obowiązujących przepisów, potrzebujemy danych organizatorów do wystawienia umów o przetwarzaniu danych osobowych uczestników.</p>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:footer>
				<base-button @click="$refs.checkOrganizerSetupModal.closeModal()" btn2 short>Anuluj</base-button>
				<base-button btn1 short @click="goToPage">Przejdź do ustawień</base-button>
			</template> 
		</modal>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import { READ_ORGANIZER_SETUP } from '@/store/actions.type';
import Modal from '@/components/Modal';

export default {
	name: 'CheckOrganizerSetup',
	props: {
        id: {
            type: Number,
			required: true
        },
		competitionId: {
			type: Number,
			default: 0
		},
		route: {
			type: String,
			default: 'panel-setting-new'
		}
	},
    components: {
        Modal,
    },
    data() {
        return {
			redirect: null
		};
    },
    computed: {},
	created() {
		this.readOrganizerSetup()
	},
    methods: {
        ...mapActions([READ_ORGANIZER_SETUP]),
		async readOrganizerSetup() {
			const payload = {
				id: this.$props.id,
				data: {
					type: this.$props.route
				}
			}
			if (this.$props.competitionId > 0) {
				payload['data']['competition_id'] = this.$props.competitionId
			}
			const data = await this.READ_ORGANIZER_SETUP(payload)
			this.redirect = data

			if (this.redirect === '') {
				this.$emit('change', 'success')
			} else {
				this.$refs.checkOrganizerSetupModal.openModal()
			}
		},
		goToPage() {
			document.querySelector("body").classList.remove("overflow-hidden");
			this.$router.push({ 
				name: this.redirect.route,
				params: {
					name: this.redirect.name,
					id: this.redirect.id,
				},
				alias: true,
			})
		}
    },
};
</script>

<style scoped>
#checkOrganizerSetupModal::v-deep .modal__dialog {color: var(--color-slate-two, #546e7a);}
@media (min-width: 992px) {
  #checkOrganizerSetupModal::v-deep .modal__body {height: auto; padding: 24px 32px;}
}
</style>